import React from "react";
import SEO from "components/seo";
import {ContactForm, ContentPhoto, FullPhoto, Header, Hero, Layout, Navigation, TextBox, WhatWeDid,} from "components/CaseLayout";
import {FIZOSTEO} from "shared/Types/cases";
import {useCaseData} from "shared/Hooks/CasesNew/useCaseData";
import BlobsLayout from "../../components/BlobsLayout/BlobsLayout";
import fizOsteoBlobs from "../../data/cases/fizosteoBlobs";

const Fizosteo = () => {
  const data = useCaseData(FIZOSTEO);

  return (
    <Layout color={data.color}>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <Hero color="#003D64" data={data.hero}/>
      <WhatWeDid data={data.what_we_did}/>
      <Navigation type={FIZOSTEO}/>
      <BlobsLayout data={fizOsteoBlobs.first}>
        <TextBox data={data.text_boxes.first}/>
        <FullPhoto data={data.full_images.first}/>
        <Header data={data.headers.first}/>
        <FullPhoto data={data.full_images.second}/>
        <TextBox data={data.text_boxes.second}/>
      </BlobsLayout>
      <BlobsLayout data={fizOsteoBlobs.second}>
        <ContentPhoto color={data.color} data={data.content_images.first}/>
        <FullPhoto data={data.full_images.third}/>
        <TextBox data={data.text_boxes.third}/>
        <ContentPhoto color={data.color} data={data.content_images.second}/>
        <ContentPhoto color={data.color} data={data.content_images.third}/>
        <FullPhoto data={data.full_images.fourth}/>
        <ContentPhoto color={data.color} data={data.content_images.fourth}/>
        <TextBox data={data.text_boxes.fourth}/>
        <FullPhoto data={data.full_images.fifth}/>
        <TextBox data={data.text_boxes.fifth}/>
      </BlobsLayout>
      <FullPhoto data={data.full_images.sixth}/>
      <ContactForm/>
    </Layout>
  );
};

export default Fizosteo;

const fizOsteoBlobs = {
  // colors: {
  //   dark_blue: "#003D64",
  //   orange: "#FF9C99",
  //   blue: "#B2EAFF"
  // },
  first: {
    type: "normal",
    items:
      [
        {
          top: '6%',
          left: "90%",
          height: '60',
          width: '60',
          color: "#003D64",
          type: "blob",
          time: "9s"
        },
        {
          top: '18%',
          left: "2%",
          height: '30',
          width: '30',
          color: "#003D64",
          type: "blob",
          time: "9s",
          rotation: "25deg",
        },
        {
          top: '19%',
          left: '90%',
          height: '40',
          width: '40',
          color: "#FF9C99",
          type: "blob",
          time: "8s",
          rotation: "10deg",
        },
        {
          top: '27%',
          left: '97%',
          height: '54',
          width: '54',
          color: "#003D64",
          type: "rectangle",
          rotation: "45deg",
        },
        {
          top: '30%',
          left: '6%',
          height: '40',
          width: '40',
          color: "#FF9C99",
          type: "blob",
          time: "10s",
          rotation: "34deg",
        },
        {
          top: '35%',
          left: '90%',
          height: '30',
          width: '30',
          color: "#003D64",
          type: "blob",
          time: "10s",
          rotation: "25deg",
        },
        {
          top: '37%',
          left: '5.5%',
          height: '64',
          width: '64',
          color: "#B2EAFF",
          type: "blob",
          rotation: "67deg",
          parallaxY: "170"
        }, {
        top: '52%',
        left: '92%',
        height: '64',
        width: '64',
        color: "#B2EAFF",
        type: "blob",
        rotation: "50deg",
        parallaxY: "150"
      },
        {
          top: '58%',
          left: '5%',
          height: '64',
          width: '64',
          color: "#003D64",
          type: "blob",
          rotation: "15deg",
          parallaxY: "0"
        },
        {
          top: '62%',
          left: '4%',
          height: '32',
          width: '32',
          color: "#B2EAFF",
          type: "blob",
          rotation: "45deg",
          parallaxY: "0"
        },
        {
          top: '64%',
          left: '95%',
          height: '64',
          width: '64',
          color: "#FF9C99",
          type: "blob",
          rotation: "85deg",
          parallaxY: "30"
        },
        {
          top: '71%',
          left: '3%',
          height: '100',
          width: '100',
          color: "#B2EAFF",
          type: "blob",
          rotation: "130deg",
          parallaxY: "50"
        },
        {
          top: '74%',
          left: '91%',
          height: '50',
          width: '50',
          color: "#003D64",
          type: "blob",
          rotation: "125deg",
          parallaxY: "50"
        },
        {
          top: '80%',
          left: '6%',
          height: '21',
          width: '21',
          color: "#B2EAFF",
          type: "blob",
          rotation: "175deg",
          parallaxY: "120"
        },
        {
          top: '84%',
          left: '95%',
          height: '64',
          width: '64',
          color: "#FF9C99",
          type: "blob",
          rotation: "110deg",
          parallaxY: "110"
        },
        {
          top: '89%',
          left: '2%',
          height: '20',
          width: '20',
          color: "#B2EAFF",
          type: "rectangle",
          rotation: "35deg",
          parallaxY: "50"
        },

        {
          top: '91%',
          left: '90%',
          height: '43',
          width: '43',
          color: "#B2EAFF",
          type: "blob",
          rotation: "90deg"
        },
        {
          top: '98%',
          left: '95%',
          height: '43',
          width: '43',
          color: "#FF9C99",
          type: "blob",
          rotation: "90deg",
          parallaxY: "0"
        },
      ]
  },

  second: {
    type: "normal",
    items:
      [
        {
          top: '3%',
          left: "90%",
          height: '73',
          width: '73',
          color: "#003D64",
          type: "blob",
          time: "15s",
          rotation: "95deg",

        },
        {
          top: '5%',
          left: "5%",
          height: '50',
          width: '50',
          color: "#B2EAFF",
          type: "blob",
          time: "9s"
        },
        {
          top: '7%',
          left: "96%",
          height: '40',
          width: '40',
          color: "#FF9C99",
          type: "blob",
          time: "15s"
        },
        {
          top: '10%',
          left: "1%",
          height: '40',
          width: '40',
          color: "#B2EAFF",
          type: "blob",
          time: "9s",
          rotation: "35deg",

        },
        {
          top: '11%',
          left: "-1%",
          height: '60',
          width: '60',
          color: "#FF9C99",
          type: "rectangle",
          rotation: "35deg"
        },
        {
          top: '10.5%',
          left: "96%",
          height: '50',
          width: '50',
          color: "#B2EAFF",
          time: "10s",
          rotation: "35deg",
          type: "blob"
        },
        {
          top: '13%',
          left: "94%",
          height: '50',
          width: '50',
          color: "#003D64",
          rotation: "55deg",
          type: "blob"
        },
        {
          top: '15%',
          left: "6%",
          height: '50',
          width: '50',
          color: "#FF9C99",
          type: "blob",
          time: "7s",
          rotation: "25deg",
          parallaxY: "170"
        },
        {
          top: '16%',
          left: "3%",
          height: '40',
          width: '40',
          color: "#B2EAFF",
          type: "blob",
          time: "10s",
          rotation: "45deg",
        },
        {
          top: '18%',
          left: "2%",
          height: '30',
          width: '30',
          color: "#003D64",
          type: "blob",
          time: "9s",
          rotation: "10deg",
        },
        {
          top: '19%',
          left: '90%',
          height: '40',
          width: '40',
          color: "#FF9C99",
          type: "blob",
          time: "8s",
          rotation: "10deg",
        }, {
        top: '20%',
        left: '95%',
        height: '50',
        width: '50',
        color: "#B2EAFF",
        type: "blob",
        time: "10s",
        rotation: "45deg",
      },
        {
          top: '23%',
          left: '5%',
          height: '44',
          width: '44',
          color: "#003D64",
          type: "blob",
          time: "7s",
          rotation: "20deg",
        }, {
        top: '25%',
        left: '98%',
        height: '58',
        width: '58',
        color: "#B2EAFF",
        type: "blob",
        time: "8s",
        rotation: "30deg",
      }, {
        top: '25%',
        left: '1%',
        height: '80',
        width: '80',
        color: "#B2EAFF",
        type: "blob",
        time: "8s",
        rotation: "80deg",
      },
        {
          top: '27%',
          left: '97%',
          height: '54',
          width: '54',
          color: "#003D64",
          type: "rectangle",
          rotation: "45deg",
        },
        {
          top: '30%',
          left: '6%',
          height: '40',
          width: '40',
          color: "#FF9C99",
          type: "blob",
          time: "10s",
          rotation: "34deg",
        },
        {
          top: '31%',
          left: '1%',
          height: '45',
          width: '45',
          color: "#B2EAFF",
          type: "blob",
          time: "10s",
          rotation: "15deg",
        },
        {
          top: '32%',
          left: '-2%',
          height: '60',
          width: '60',
          color: "#003D64",
          type: "rectangle",
          time: "10s",
          rotation: "15deg",
        },
        {
          top: '32%',
          left: '95%',
          height: '32',
          width: '32',
          color: "#FF9C99",
          type: "blob",
          time: "8s",
          rotation: "5deg",
        },
        {
          top: '33%',
          left: '98%',
          height: '80',
          width: '80',
          color: "#003D64",
          type: "rectangle",
          time: "10s",
          rotation: "35deg",
        },
        {
          top: '35%',
          left: '90%',
          height: '30',
          width: '30',
          color: "#003D64",
          type: "blob",
          time: "10s",
          rotation: "25deg",
        },
        {
          top: '37%',
          left: '7%',
          height: '32',
          width: '32',
          color: "#B2EAFF",
          type: "blob",
          rotation: "29deg",
          parallaxY: "170"
        },
        {
          top: '37%',
          left: '97%',
          height: '45',
          width: '45',
          color: "#FF9C99",
          type: "blob",
          rotation: "66deg",
          parallaxY: "150"
        },
        {
          top: '37.5%',
          left: '94%',
          height: '35',
          width: '35',
          color: "#003D64",
          type: "rectangle",
          time: "10s",
          rotation: "80deg",
          parallaxY: "150"
        },
        {
          top: '39%',
          left: '95%',
          height: '35',
          width: '35',
          color: "#FF9C99",
          type: "blob",
          rotation: "15deg",
          parallaxY: "150"
        },
        {
          top: '42%',
          left: '97%',
          height: '67',
          width: '67',
          color: "#FF9C99",
          type: "blob",
          rotation: "66deg",
          parallaxY: "110"
        },
        {
          top: '42%',
          left: '3%',
          height: '45',
          width: '45',
          color: "#FF9C99",
          type: "blob",
          rotation: "66deg",
          parallaxY: "130"
        },
        {
          top: '43%',
          left: '-2%',
          height: '55',
          width: '55',
          color: "#003D64",
          type: "rectangle",
          rotation: "66deg",
          parallaxY: "150"
        },
        {
          top: '43%',
          left: '94%',
          height: '20',
          width: '20',
          color: "#003D64",
          type: "rectangle",
          time: "10s",
          rotation: "80deg",
          parallaxY: "180"
        },
        {
          top: '45%',
          left: '95%',
          height: '40',
          width: '40',
          color: "#FF9C99",
          type: "blob",
          rotation: "15deg",
          parallaxY: "150"
        },
        {
          top: '49%',
          left: '1%',
          height: '40',
          width: '40',
          color: "#FF9C99",
          type: "blob",
          rotation: "45deg",
          parallaxY: "150"
        },
        {
          top: '50%',
          left: '-2%',
          height: '45',
          width: '45',
          color: "#B2EAFF",
          type: "rectangle",
          rotation: "33deg",
          parallaxY: "190"
        },
        {
          top: '51%',
          left: '3%',
          height: '25',
          width: '25',
          color: "#003D64",
          type: "blob",
          rotation: "33deg",
          parallaxY: "150"
        },
        {
          top: '50%',
          left: '95%',
          height: '30',
          width: '30',
          color: "#FF9C99",
          type: "blob",
          rotation: "88deg",
          parallaxY: "110",
          time: "9s"
        },
        {
          top: '51%',
          left: '97%',
          height: '100',
          width: '100',
          color: "#003D64",
          type: "rectangle",
          rotation: "63deg",
          parallaxY: "78",
          time: "20s"
        },
        {
          top: '53%',
          left: '93%',
          height: '35',
          width: '35',
          color: "#B2EAFF",
          type: "rectangle",
          rotation: "63deg",
          parallaxY: "100",
          time: "15s"
        },
        {
          top: '57%',
          left: '5%',
          height: '64',
          width: '64',
          color: "#003D64",
          type: "blob",
          rotation: "110deg",
          parallaxY: "0"
        },
        {
          top: '59%',
          left: '3%',
          height: '64',
          width: '64',
          color: "#B2EAFF",
          type: "blob",
          rotation: "78deg",
          parallaxY: "150"
        },
        {
          top: '59%',
          left: '98%',
          height: '35',
          width: '35',
          color: "#B2EAFF",
          type: "blob",
          rotation: "58deg",
          parallaxY: "150"
        },
        {
          top: '60%',
          left: '95%',
          height: '45',
          width: '45',
          color: "#FF9C99",
          type: "blob",
          rotation: "35deg",
          parallaxY: "120"
        },
        {
          top: '62%',
          left: '4%',
          height: '32',
          width: '32',
          color: "#B2EAFF",
          type: "blob",
          rotation: "30deg",
          parallaxY: "0"
        },
        {
          top: '63%',
          left: '6%',
          height: '55',
          width: '55',
          color: "#003D64",
          type: "blob",
          rotation: "88deg",
          parallaxY: "0"
        },
        {
          top: '64%',
          left: '95%',
          height: '64',
          width: '64',
          color: "#FF9C99",
          type: "blob",
          rotation: "110deg",
          parallaxY: "30"
        },
        {
          top: '69%',
          left: '95%',
          height: '38',
          width: '38',
          color: "#FF9C99",
          type: "blob",
          rotation: "110deg",
          parallaxY: "30"
        },
        {
          top: '69%',
          left: '9%',
          height: '55',
          width: '55',
          color: "#B2EAFF",
          type: "blob",
          rotation: "55deg",
          parallaxY: "30"
        },
        {
          top: '71%',
          left: '3%',
          height: '90',
          width: '90',
          color: "#003D64",
          type: "blob",
          rotation: "136deg",
          parallaxY: "50"
        },
        {
          top: '74%',
          left: '91%',
          height: '50',
          width: '50',
          color: "#003D64",
          type: "blob",
          rotation: "110deg",
          parallaxY: "50"
        },
        {
          top: '80%',
          left: '6%',
          height: '21',
          width: '21',
          color: "#B2EAFF",
          type: "blob",
          rotation: "175deg",
          parallaxY: "120"
        },
        {
          top: '82%',
          left: '2%',
          height: '70',
          width: '70',
          color: "#003D64",
          type: "blob",
          rotation: "37deg",
          parallaxY: "120"
        },
        {
          top: '84%',
          left: '-1%',
          height: '55',
          width: '55',
          color: "#B2EAFF",
          type: "rectangle",
          rotation: "15deg",
          parallaxY: "120"
        },
        {
          top: '84%',
          left: '95%',
          height: '64',
          width: '64',
          color: "#FF9C99",
          type: "blob",
          rotation: "110deg",
          parallaxY: "110"
        },
        {
          top: '89%',
          left: '2%',
          height: '20',
          width: '20',
          color: "#B2EAFF",
          type: "rectangle",
          rotation: "35deg",
          parallaxY: "50"
        },
        {
          top: '91%',
          left: '10%',
          height: '80',
          width: '80',
          color: "#003D64",
          type: "blob",
          rotation: "15deg"
        },
        {
          top: '91%',
          left: '90%',
          height: '43',
          width: '43',
          color: "#B2EAFF",
          type: "blob",
          rotation: "90deg"
        },
        {
          top: '93%',
          left: '6%',
          height: '60',
          width: '60',
          color: "#FF9C99",
          type: "blob",
          rotation: "70deg"
        },
        {
          top: '98%',
          left: '90%',
          height: '43',
          width: '43',
          color: "#B2EAFF",
          type: "blob",
          rotation: "90deg"
        },
      ]
  }
}

export default fizOsteoBlobs
